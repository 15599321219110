
  import { Component, Prop } from 'vue-property-decorator'

  import BaseBar from '@/layouts/BaseBar'
  import { mapGetters } from 'vuex'
  import { Crumb } from '@/store/resources/form/state'
  import GBreadcrumbs from '@/components/GBreadcrumbs.vue'

@Component({
  components: { GBreadcrumbs },
  computed: {
    ...mapGetters('resources/form', ['crumbs']),
  },
})
  export default class AppBar extends BaseBar {
  @Prop({ type: Number }) uid?: number
  @Prop({ type: String }) returnTo?: string

  crumbs!: Array<Crumb>

  goBack () {
    return this.$router.back()
  }
  }
